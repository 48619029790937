<template>
    <div class="surgeon-table-container px-0 mx-0">
        <b-table
            responsive
            fixed
            no-local-sorting
            id="table-surgeon"
            class="table-list"
            :items="doctors"
            :fields="fields"
            :sort-by="queryParams.sortBy"
            :sort-desc="queryParams.sortOrder === 'desc'"
            v-on="$listeners"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>
            <template #head(updated)="data">
                <div class="right-light-border">
                    <div class="d-flex column-explanation">
                        <div>
                            {{ data.label }}
                            <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <template #head(fullName)="data">
                <div class="content">
                    <div class="d-flex column-explanation">
                        <div>
                            {{ data.label }}
                            <div class="mt-1 column-explanation-text">
                                {{ t('LastFirst') }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #cell(fullName)="data">
                <div class="d-flex">
                    <div>
                        <p class="mb-1">
                            <custom-router-link
                                :to="{
                                    name: 'SurgeonDetails',
                                    params: {doctorId: data.item.doctorId},
                                }"
                                class="record-link text-primary"
                                :disabled="!hasSurgeonPermission"
                            >
                                {{ data.item.lastName }}, {{ data.item.firstName }}
                            </custom-router-link>
                        </p>
                        <p class="text-caption light text-gray-dark mb-0">
                            ID: {{ data.item.contactPartyNumber }}
                        </p>
                    </div>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import date from '@/filters/date';

export default {
    components: {},
    props: {
        queryParams: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            fields: [
                {
                    key: 'updated',
                    label: this.t('activity'),
                    sortable: true,
                    tdClass: 'text-caption text-gray-dark',
                    formatter: (value) => date(value),
                },
                {key: 'fullName', label: this.t('surgeonList_SurgeonName'), sortable: true},
                {
                    key: 'city',
                    label: this.t('invLookupCol_Location'),
                    sortable: false,
                    formatter: (_, __, item) =>
                        [item.city, item.state, item.zip, item.countryCode]
                            .filter((x) => x)
                            .join(', '),
                },
                {
                    key: 'active',
                    label: this.t('active'),
                    sortable: false,
                    formatter: (value) => (value ? this.t('yes') : this.t('no')),
                    tdClass: (value) => (value ? 'text-success' : 'text-danger'),
                },
                {
                    key: 'certifications',
                    label: this.t('surgeonDetails_Certifications'),
                    tdClass: 'text-caption heavy',
                    sortable: false,
                    formatter: (value) => value?.map((c) => c.toUpperCase()).join(', '),
                },
            ],
            colWidths: {
                //based on a 1536px width resolution screen
                updated: '108px',
                fullName: '575px',
                city: '275px',
                active: '92px',
                certifications: '500px',
            },
        };
    },
    computed: {
        ...mapState({
            doctors: (state) => state.doctors.list,
        }),
        hasSurgeonPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.SURGEONS]: [
                    this.PERMISSIONS_VALUES.READ_ONLY,
                    this.PERMISSIONS_VALUES.READ_WRITE,
                    this.PERMISSIONS_VALUES.LIMITED_READ_ONLY,
                ],
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

::v-deep .light-border-right {
    border-right: 1px solid $gray-light;
}
</style>
